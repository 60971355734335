$(document).ready(function () {

    // touch device detection function *WARNING* feature rapidly evolving, may need update
    function DetectTouchDevice() {

        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

        var mq = function (query) {
            return window.matchMedia(query).matches;
        }

        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    }

    let isTouchDevice = DetectTouchDevice();
    let pendingTimeout = null;
    let apendingTimeout = null;

    //show thirdLevelNav on hover secondLevelNav
    let elsTarget = [];
    elsTarget = document.querySelectorAll('.instrument .desktop-menu .productsNav .nav-second ')
    if(elsTarget.length > 0 ) {
        elsTarget[1].classList.add('active')
        $('.nav-second').hover(function () {
            $('.nav-second').removeClass('active');
            $(this).addClass('active');
        })
    }

    // show and hide down menu on click for devices which can't handle hover
    $('.header__mouseOver').click(function(event) {

        let activeState = $(".menu-container .menu-list").hasClass("active");
        if (activeState) {
            return;
        }
        $(this).siblings().removeClass('open');

        if ($(this).hasClass('open')) {
            $(this).removeClass('open');
        } else {
            $(this).addClass('open');
        }
    });

    // click anywhere to close the selection menu
    $(document).click(function(event) {

        let activeState = $(".menu-container .menu-list").hasClass("active");
        if (activeState) {
            return;
        }
        
        if ($(event.target).closest('#navbarSmegContent').length === 0) {
            $('.header__mouseOver').removeClass('open');
        }
    });

    // Show then hide down menu on hover; deactivated on touch devices
    $('.header__mouseOver').mouseenter(function () {

        if (isTouchDevice) {
            return
        }

        let activeState = $(".menu-container .menu-list").hasClass("active");
        if (activeState) {
            return;
        }

        if (pendingTimeout) {
            clearTimeout(pendingTimeout);
        }

        if (apendingTimeout) {
            clearTimeout(apendingTimeout);
        }

        $('.header__mouseOver').removeClass('open');

        let _this = this;
        apendingTimeout = setTimeout(function(){

            $(_this).addClass('open');

        },500);


    }).mouseleave(function(){
        let _this = this;
        pendingTimeout = setTimeout(function(){$(_this).removeClass('open');},500);
    });

    $(document).on("click", ".store__hasSubmenu .store__link" ,function(e){
        e.preventDefault();
        $(this).siblings(".store__submenu").toggleClass('active');
    })
});

// TODO: functionality under scrutiny, cancel if discarded, uncomment if approved;
//animate image on aesthetic line navbar
/*TweenLite.set(".imagesAnimation__image", {autoAlpha: 0, xPercent:0})

var $page = $('.imagesAnimation__image'),
    $button = $('.nav__item .js-showImage');

$button.on('mouseenter', function(e) {
    var $thisPage = $(this).attr('id');

    TweenMax.to($thisPage, 0.5, {autoAlpha: 1, ease: Sine.easeInOut});
});

$button.on('mouseleave', function(e) {
    TweenMax.to($page, 0.4, {autoAlpha: 0, ease: Power2.easeIn});
});*/
